// import React, { lazy, useEffect } from "react";
// import {
//   BrowserRouter,
//   useLocation,
//   useNavigate,
//   useParams,
// } from "react-router-dom";
// import { Box, useMediaQuery } from "@material-ui/core";
// import { onEvent, disconnectSocket } from "./helper/socketService.js";
// import { useDispatch } from "react-redux";
// import { setIsMobile } from "./reducer/responsiveReducer.js";
// import "./App.css";
// import { VerifyAccess } from "./api/api.js";
// import { useSelector } from "react-redux";
// import { setEmail } from "./reducer/emailReducer.js";
// import { setAccessToken } from "./reducer/tokenReducer.js";
// import {
//   setAddress,
//   setAllData,
//   setFirstName,
//   setHostId,
//   setIsHost,
//   setIsUserVerified,
//   setLastName,
//   setPhoneNumber,
//   setProfilePicture,
// } from "./reducer/userDetailsReducer.js";

// // Lazy load your components
// const HeaderWrapper = lazy(() => import("./Layout/HeaderWrapper/index.js"));
// const Router = lazy(() => import("./routes/router.js"));
// const FooterWrapper = lazy(() => import("./Layout/FooterWrapper/index.js"));

// const App = () => {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const responsiveMobile = useMediaQuery("(max-width:600px)");
//   const accessToken = useSelector((state) => state.token.accessToken);
//   const email = useSelector((state) => state.email);

//   useEffect(() => {
//     onEvent("connect", () => {
//       console.info("SOCKET CONNECTED");
//     });

//     return () => {
//       disconnectSocket();
//     };
//   }, []);

//   useEffect(() => {
//     dispatch(setIsMobile(responsiveMobile));
//   }, [responsiveMobile]);

//   async function verifyAccess() {
//     try {
//       const { response } = await VerifyAccess(accessToken, email);
//       if (response.status !== 200) {
//         dispatch(setEmail(""));
//         dispatch(setAccessToken(""));
//         dispatch(setAllData(""));
//         dispatch(setFirstName(""));
//         dispatch(setLastName(""));
//         dispatch(setPhoneNumber(""));
//         dispatch(setAddress(""));
//         dispatch(setIsHost(""));
//         dispatch(setHostId(""));
//         dispatch(setIsUserVerified(""));
//         dispatch(setProfilePicture(""));
//         navigate("/login-signup");
//       }
//     } catch (error) {
//       return error;
//     }
//   }

//   useEffect(() => {
//     if (accessToken && email) {
//       verifyAccess();
//     }
//   }, [accessToken, email]);

//   return (
//     <Box style={{ paddingBottom: "50px" }}>
//       {/* <BrowserRouter> */}
//       <HeaderWrapper />
//       <Router />
//       <FooterWrapper />
//       {/* </BrowserRouter> */}
//     </Box>
//   );
// };

// export default App;

import React, { lazy, useEffect } from "react";
import {
  BrowserRouter,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Box, useMediaQuery } from "@material-ui/core";
import { onEvent, disconnectSocket } from "./helper/socketService.js";
import { useDispatch } from "react-redux";
import { setIsMobile } from "./reducer/responsiveReducer.js";
import "./App.css";
import { VerifyAccess } from "./api/api.js";
import { useSelector } from "react-redux";
import { setEmail } from "./reducer/emailReducer.js";
import { setAccessToken } from "./reducer/tokenReducer.js";
import {
  setAddress,
  setAllData,
  setFirstName,
  setHostId,
  setIsHost,
  setIsUserVerified,
  setLastName,
  setPhoneNumber,
  setProfilePicture,
} from "./reducer/userDetailsReducer.js";

// Lazy load your components
const HeaderWrapper = lazy(() => import("./Layout/HeaderWrapper/index.js"));
const Router = lazy(() => import("./routes/router.js"));
const FooterWrapper = lazy(() => import("./Layout/FooterWrapper/index.js"));

const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const responsiveMobile = useMediaQuery("(max-width:600px)");
  const accessToken = useSelector((state) => state.token.accessToken);
  const email = useSelector((state) => state.email);

  useEffect(() => {
    onEvent("connect", () => {
      console.info("SOCKET CONNECTED");
    });

    onEvent("connect_error", (error) => {
      console.error("SOCKET CONNECTION ERROR: ", error);
    });

    return () => {
      disconnectSocket();
    };
  }, []);

  useEffect(() => {
    dispatch(setIsMobile(responsiveMobile));
  }, [responsiveMobile]);

  async function verifyAccess() {
    try {
      const response = await VerifyAccess(accessToken, email);
      if (response?.statusCode !== 200) {
        dispatch(setEmail(""));
        dispatch(setAccessToken(""));
        dispatch(setAllData(""));
        dispatch(setFirstName(""));
        dispatch(setLastName(""));
        dispatch(setPhoneNumber(""));
        dispatch(setAddress(""));
        dispatch(setIsHost(""));
        dispatch(setHostId(""));
        dispatch(setIsUserVerified(""));
        dispatch(setProfilePicture(""));
        navigate("/login-signup");
      }
    } catch (error) {
      console.error("VERIFY ACCESS ERROR: ", error);
      return error;
    }
  }

  useEffect(() => {
    if (accessToken && email) {
      verifyAccess();
    }
  }, [accessToken, email]);

  return (
    <Box style={{ paddingBottom: "50px" }}>
      <HeaderWrapper />
      <Router />
      <FooterWrapper />
    </Box>
  );
};

export default App;
