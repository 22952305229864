import React from "react";
import {
  makeStyles,
  CircularProgress,
  Box,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  Loader: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(255, 255, 255, 0.8)",
    backgroundImage:
      "url(https://vacanza.s3.amazonaws.com/Images/new_vacanza_logo.png) !important",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 999999,
    right: 0,
    margin: "0px auto",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "50% 39%",
    opacity: 0.9,
    backgroundColor: "#fff",
    backgroundSize: "auto 10%", // Adjust this value to change the height
  },
}));

const Index = () => {
  const classes = useStyles();
  return (
    <Box className={classes.Loader}>
      <Typography>Please wait while loading...</Typography>
      <CircularProgress variant="indeterminate" style={{ color: "#ff7b28" }} />
    </Box>
  );
};

export default Index;
